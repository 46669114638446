// Footer.js
import React from 'react';
import CAIFOJ from "./img/cai-foj-800.png";
const Footer = () => (
    <section className="footer">

        <img className={"caifoj"} src={CAIFOJ} alt={"Content Authenticity Initiative and Friends of Justin"} />

        <div className={"footer"}>

                &copy;Copyright 2024, Friends of Justin, All Rights Reserved


        </div>
    </section>
);

export default Footer;


