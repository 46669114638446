// HeroSection.js
import React from 'react';
import Handprint from './img/Friends_of_Justin-hand_only.png';
import Hero2 from './HeroSection2';

const HeroSection1 = () => (
    <div className={"content"}>

        <div className={"banner"}>
            <span className={"Handprint"}><img src={Handprint} alt={"Friends of Justin Six-Fingered Handprint Logo"} /></span>
            <h1 className="booktitle">Friends of Justin</h1>
            <h2>Connecting Humans and Artificial Intelligence</h2>
        </div>

        <Hero2 />
        <div className={"hero-img"}>
             <h3>Collaborating for Responsible AI</h3>
            <p>Friends of <a href={"https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721086643805-generated-page.html"}>Justin</a>, <a href={"https://file.baby"}>File Baby</a>, and <a href={"https://file.baby/nyx-nocode"}>NYX NoCode</a> collaborate for responsible AI, working together to create human-friendly AI interfaces that help you manage your content authenticity and provenance. Reach out to us via <a href={"https://www.linkedin.com/company/friends-of-justin/"}>LinkedIn</a> for more information on our mission and how you can help.</p>
        </div>
    </div>


);
export default HeroSection1;