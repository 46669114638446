// HeroSection.js
import React from 'react';
import Edge from './img/edge_of_the_universe.png';


const HeroSection2 = () => (
    <div className="content">
            <p><strong>Friends of Justin</strong> is a non-profit AI Research Lab located in Bentonville, Arkansas, striving to ease the interaction between humans and AI. The sixth finger on our hand print logo symbolizes the extra capabilities that humanity can enjoy as a result of working with AI. The heart inset symbolizes our love for people and our drive to end poverty. </p>
         <p>An active member of <a href={"https://c2pa.org"}>Coalition for Content Provenance and Authenticity (C2PA)</a>, the <a href={"https://contentauthenticity.org"}>Content Authenticity Initiative</a>, and the Creator Assertions Working Group, Friends of Justin is not only following–but helping to establish–industry best practices for content authenticity and responsible AI.</p>
        <h3>Our Mission</h3>
        <p>Friends of Justin's ultimate mission is to eliminate poverty by helping anyone, even those who can&#39;t conform with requirements of a &quot;normal&quot; job, to be paid through selling their original content as AI training data. As white collar work shifts to a gig economy paradigm, this new way of working will help people to avoid welfare and to have a greater sense of self-worth. We will accomplish this through setting standards and spreading education on how people can claim their work and be paid for it, while at the same time, acknowledging and celebrating the contributions made by AI.</p>
           <img src={Edge} className="edge" alt={"edge of the universe painting by DALLE"} />
    </div>

);
export default HeroSection2;